.logo-cliente {
  height: 25px;
  align-items: center;
}

.top-menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-options {
  display: flex;
  align-items: center;
}
