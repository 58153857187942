.login {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: calc(100vh - 56px - 50px);
  width: 100%;
  position: relative;
  @media (min-width: 600px) {
    min-height: calc(100vh - 64px - 50px);
  }
  .title {
    padding: 40px 0 0 30px;
    text-align: left;
    margin: 0;
    font-size: 35px;
    color: rgb(243, 243, 243);
    @media (min-width: 600px) {
      font-size: 50px;
    }
  }
  .subtitle {
    margin: 20px auto;
    text-align: center;
    width: 90%;
    height: 100%;

    color: rgb(243, 243, 243);
    @media (min-width: 600px) {
      width: 50%;
      margin: 20px 0;
      text-align: left;
      padding-left: 30px;
    }
  }
}

.btn-login {
  min-width: 20%;
}

.btn-box {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media (min-width: 600px) {
    padding-top: 50px;
  }
  h3 {
    color: rgb(255, 255, 255);
  }
  p {
    margin: 0;
  }
}
.btn-img {
  height: 100px;
}
