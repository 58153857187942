.reportes {
  background-color: rgb(223, 222, 222);
  min-height: calc(100vh - 56px - 50px);
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    min-height: calc(100vh - 64px - 50px);
    grid-template-columns: 20% 80%;
    display: grid;
    grid-template-columns: repeat(1fr, 100%);
  }
}

.lista-reportes {
  background-color: #0087ca;
  height: 100%;

  @media (min-width: 600px) {
    min-height: calc(100vh - 64px - 50px);
  }

  .nombre-usuario {
    text-align: left;
    background-color: rgb(223, 222, 222);
    margin: 0;
    padding: 15px;
  }
  p {
    text-align: left;
    padding-left: 10px;
    :hover {
      cursor: pointer;
    }
  }
}

.reporte-disponible:hover {
  cursor: pointer;
}

.mostrar-reporte {
  display: flex;
  text-align: center;
  align-items: center;
  .msj-espera {
    font-weight: 500;
    width: 100%;
  }
}
