.footer {
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  background-color: #8f8f8f;
  height: 50px;
  position: relative;
  @media (min-height: 600px) {
    height: 50px;
  }
  p {
    margin-right: 5px;
    color: white;
  }
  a {
    margin-right: 10px;
    text-decoration: none;
    color: white;
    font-weight: bold;
    :hover {
      cursor: pointer;
    }
  }
  img {
    display: none;
    @media (min-width: 600px) {
      display: block;
      position: absolute;
      right: 10px;
    }
  }
}
